import React, { Component } from "react";
import {
	Input,
	Select,
	MenuItem,
	Typography,
	withStyles
} from "@material-ui/core";
import "../../stateface/stateface.css";
import StateInfo from "./state-info";

const stateIconStyles = theme => {
	const u = theme.spacing.unit;
	return {
		icon: {
			fontSize: "1.5em",
			width: "1.5em",
			verticalAlign: "middle",
			marginLeft: u / 2
		}
	};
};

const _StateIcon = ({ code, classes }) => (
	<span
		className={`${classes.icon} stateface stateface-${code.toLowerCase()}`}
	/>
);

const StateIcon = withStyles(stateIconStyles)(_StateIcon);

/* eslint-disable react/display-name */
const renderValue = jurisdictions => value =>
	value ? (
		<>
			<StateIcon code={jurisdictions[value].code} /> {jurisdictions[value].name}
		</>
	) : (
		<>Choose…</>
	);
/* eslint-enable react/display-name */

class StateChooser extends Component {
	onStateChosen = ({ target: { value } }) => {
		value && this.props.onStateChosen(value);
	};

	render() {
		const { jurisdiction, jurisdictions, content } = this.props;

		return (
			<div>
				<Typography
					component="div"
					variant="body1"
					gutterBottom
					dangerouslySetInnerHTML={{ __html: content }}
				/>

				<Select
					displayEmpty
					input={<Input />}
					name="state"
					value={jurisdiction || ""}
					onChange={this.onStateChosen}
					renderValue={jurisdiction ? renderValue(jurisdictions) : undefined}
				>
					<MenuItem value="">None</MenuItem>
					{Object.keys(jurisdictions).map(key => {
						const { code, name } = jurisdictions[key];
						return (
							<MenuItem key={key} value={key}>
								<StateIcon code={code} />
								{name}
							</MenuItem>
						);
					})}
				</Select>
				{jurisdiction ? <StateInfo {...this.props} /> : null}
			</div>
		);
	}
}

export default StateChooser;

import React, { Component } from "react";
import { TextField as Field, withStyles } from "@material-ui/core";

const preShrunk = { shrink: true };

class TextField extends Component {
	// https://github.com/mui-org/material-ui/pull/9216
	onChange = ({ target: { name, value } }) => {
		if (this.props.onChange) {
			this.props.onChange({
				name,
				value
			});
		}
	};

	render() {
		const { classes, selectEmpty, ...props } = this.props;

		const cx = `${
			selectEmpty ? classes.selectEmpty : classes.formControl
		} ${props.className || ""}`;

		return (
			<Field
				InputLabelProps={props.placeholder ? preShrunk : null}
				{...props}
				className={cx}
				onChange={this.onChange}
			/>
		);
	}
}

const styles = theme => {
	const u = theme.spacing.unit;
	return {
		formControl: {
			marginRight: u * 2
		},
		selectEmpty: {
			marginRight: u * 2,
			marginTop: u * 2
		}
	};
};

export default withStyles(styles)(TextField);

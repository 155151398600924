import React, { PureComponent } from "react";

import { withStyles } from "@material-ui/core";
import { get } from "lodash";
import { propertyDetailsFields } from "./property-details";
import { addressDetailsFields } from "./address-details";

import { FormValidator } from "@civicsource/ui";

import connect from "./connect";
import enforceLogin from "../../login";

import NewOrderRoot from "./root";

const Form = props => <form noValidate {...props} />;

class NewOrderContainer extends PureComponent {
	state = {
		activeStep: 0,
		hasNoAddress: false,
		isAdjudicated: false
	};

	componentDidUpdate(prevProps) {
		// if order is succesffully submitted, redirect to the checkout page
		const wasSubmitting = get(prevProps, "order.isSubmitting");
		const isSubmitting = get(this.props, "order.isSubmitting");
		const submitError = get(this.props, "order.isSubmitError");

		if (wasSubmitting && !isSubmitting && !submitError) {
			this.props.navigate("/checkout");
		}
	}

	handlePrev = () => {
		this.setState(({ activeStep }) => {
			return { activeStep: activeStep - 1 };
		});
	};

	handleNext = () => {
		this.setState(({ activeStep }) => {
			return { activeStep: activeStep + 1 };
		});
	};

	handleSelect = step => {
		this.setState({ activeStep: step });
	};

	handleChange = ({ target: { type, name, value, checked } }) => {
		if (type === "checkbox") {
			this.setState({ [name]: checked });
		} else {
			this.setState({ [name]: value });
		}
	};

	handleHasNoAddressChange = () =>
		this.setState(({ hasNoAddress }) => ({ hasNoAddress: !hasNoAddress }));

	handleStateChosen = jurisdiction => {
		this.setState({ jurisdiction });
	};

	handleSubmit = data => {
		this.props.submitOrder({
			jurisdictionCode: this.state.jurisdiction,
			accountNumberType: data.referenceNumberName,
			accountNumber: data.accountNumber,
			address: {
				address1: data.propertyAddress1,
				address2: data.propertyAddress2,
				city: data.propertyCity,
				state: this.props.jurisdictions[this.state.jurisdiction].code,
				postalCode: data.propertyPostalCode
			}
		});
	};

	render() {
		return (
			<div onChange={this.handleChange}>
				<FormValidator
					containerComponent={Form}
					ignoreChange={["isAdjudicated", "hasNoAddress"]}
					fields={{
						...propertyDetailsFields,
						...addressDetailsFields({
							isRequired: !this.state.hasNoAddress,
							prefix: "property"
						})
					}}
					onSubmit={this.handleSubmit}
				>
					<NewOrderRoot
						{...this.props}
						{...this.state}
						handlePrev={this.handlePrev}
						handleNext={this.handleNext}
						handleSelect={this.handleSelect}
						onStateChosen={this.handleStateChosen}
						onHasNoAddressChange={this.handleHasNoAddressChange}
					/>
				</FormValidator>
			</div>
		);
	}
}

const styles = theme => {
	return {
		"@global html": {
			fontSize: 16
		},
		root: {
			...theme.typography.body1
		}
	};
};

export default enforceLogin(connect(withStyles(styles)(NewOrderContainer)));

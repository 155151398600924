import React from "react";
import { Fade, Paper, withStyles } from "@material-ui/core";
import { CloudUpload } from "@material-ui/icons";
import tc from "tinycolor2";
import ShadowIcon from "./shadow-icon";

const DndTarget = ({ active, classes }) => {
	return (
		<Fade in={active}>
			<div>
				<div className={active ? classes.dndZoneActive : classes.dndZoneHidden}>
					<div className={classes.dndHelpText}>
						<ShadowIcon>
							<CloudUpload
								className={
									active ? classes.bigIconActive : classes.bigIconInactive
								}
							/>
						</ShadowIcon>
						<Paper elevation={6} className={classes.card}>
							<div className={classes.dndHelpTextInner}>Drop files here</div>
						</Paper>
					</div>
				</div>
			</div>
		</Fade>
	);
};

const styles = theme => {
	const z = theme.zIndex;
	const u = theme.spacing.unit;
	const { grey, primary } = theme.palette;
	const { body2 } = theme.typography;
	const { duration, easing } = theme.transitions;

	const borderWidth = u / 4;
	const dashLength = u * 2;
	const dashAnimDistance = dashLength * 2;

	const borderColor = tc(primary[500])
		.setAlpha(0.3)
		.toString();

	const borderColor2 = tc(primary[500])
		.setAlpha(0)
		.toString();

	const horizontalDash = `
		linear-gradient(to right, ${borderColor} 50%, ${borderColor2} 50%)
	`;

	const verticalDash = `
		linear-gradient(to bottom, ${borderColor} 50%, ${borderColor2} 50%)
	`;

	return {
		"@keyframes cloud-hop": {
			from: { transform: "translateY(-2px) scale(1.01)" },
			to: { transform: "translateY(2px) scale(0.99)" }
		},
		card: {
			background: grey[900],
			padding: [u / 2, u],
			maxWidth: u * 32,
			margin: "0 auto",
			marginBottom: u * 2
		},
		dndHelpText: {
			zIndex: z.dialogOverlay + 1,
			flex: "0 1 auto",
			alignSelf: "auto",
			textAlign: "center"
		},
		bigIcon: {
			display: "inline-block",
			color: "#fff",
			width: u * 10,
			height: u * 10,
			transition: ".5s"
		},
		bigIconInActive: {
			composes: "$bigIcon",
			transform: "scale(2)"
		},
		bigIconActive: {
			composes: "$bigIcon",
			transform: "scale(1)",
			animation: `${duration.standard}ms infinite alternate cloud-hop ${
				easing.easeInOut
			}`
		},
		dndHelpTextInner: {
			...body2,
			textAlign: "center",
			color: grey[50]
		},
		dndZoneHidden: {
			width: 0,
			height: 0,
			overflow: "hidden",
			transitionDuration: ".2s",
			opacity: 0,
			transitionProperty: "opacity, transform",
			transform: "scale(1.5)"
		},
		"@keyframes border-dance": {
			"0%": {
				backgroundPosition: "0 0, 0 100%, 0 0, 100% 0"
			},
			"100%": {
				backgroundPosition: `
					${dashAnimDistance}px 0,
					-${dashAnimDistance}px 100%,
					0 -${dashAnimDistance}px,
					100% ${dashAnimDistance}px`
			}
		},
		dndZoneActive: {
			opacity: 1,
			transition: ".2s transform",
			display: "flex",
			flexDirection: "row",
			justifyContent: "center",
			alignItems: "flex-end",
			zIndex: z.dialogOverlay,
			border: 0,
			position: "absolute",
			transform: "scale(1)",
			top: -borderWidth,
			right: -borderWidth,
			bottom: -borderWidth,
			left: -borderWidth,
			borderRadius: u / 2,
			borderColor: "transparent",
			background: `
				${horizontalDash},
				${horizontalDash},
				${verticalDash},
				${verticalDash}`,
			backgroundRepeat: "repeat-x, repeat-x, repeat-y, repeat-y",
			backgroundSize: `
				${dashLength}px ${borderWidth}px,
				${dashLength}px ${borderWidth}px,
				${borderWidth}px ${dashLength}px,
				${borderWidth}px ${dashLength}px`,
			backgroundPosition: "0 0, 0 100%, 0 0, 100% 0",
			backgroundColor: tc(primary[400])
				.setAlpha(0.3)
				.toString(),
			animation: `border-dance ${duration.complex}ms infinite linear`
		}
	};
};

export default withStyles(styles)(DndTarget);

import React from "react";
import { Typography, Card, CardContent } from "@material-ui/core";
import { startCase } from "lodash";

import { Address } from "@civicsource/ui";

import connect from "./documents/connect";
import DocumentReviewTable from "./documents/review/table";

const Review = ({
	accountNumber,
	referenceNumberName,

	jurisdiction,
	jurisdictions,

	propertyAddress1,
	propertyAddress2,
	propertyCity,
	propertyPostalCode,
	hasNoAddress,
	content,
	...props
}) => {
	const address = {
		address1: propertyAddress1,
		address2: propertyAddress2,
		city: propertyCity,
		state: jurisdictions[jurisdiction].code,
		postalCode: propertyPostalCode
	};

	const addressComponent = hasNoAddress ? (
		<>Unspecified</>
	) : (
		<Address singleLine address={address} />
	);

	return (
		<div>
			<Typography
				component="div"
				variant="body1"
				gutterBottom
				dangerouslySetInnerHTML={{ __html: content }}
			/>
			<Card elevation={3}>
				<CardContent>
					<div>
						<Typography variant="title">{addressComponent}</Typography>{" "}
						<Typography variant="subheading" color="secondary" gutterBottom>
							{startCase(referenceNumberName)} Number {accountNumber}
						</Typography>
					</div>
					<DocumentReviewTable items={props.items} />
				</CardContent>
			</Card>
		</div>
	);
};

export default connect(Review);

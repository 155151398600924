import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
import Dropzone from "react-dropzone";
import DropTarget from "./dnd-target";

class OrderDocumentDndWrapper extends Component {
	state = {
		showBigZone: false
	};

	onDragEnter = () => {
		this.setState({
			showBigZone: true
		});
	};

	onDragLeave = () => {
		this.setState({
			showBigZone: false
		});
	};

	onUpload = files => {
		this.setState({
			showBigZone: false
		});
		this.props.uploadOrderDocuments(this.props.jurisdiction, files);
	};

	noop = Function.prototype;

	render() {
		const { classes, children, disabled } = this.props;
		return (
			<Dropzone
				disabled={disabled}
				className={classes.dndZoneWrapper}
				onDragEnter={disabled ? this.noop : this.onDragEnter}
				onDragLeave={disabled ? this.noop : this.onDragLeave}
				disableClick
				onDrop={disabled ? this.noop : this.onUpload}
			>
				{children}
				<DropTarget active={this.state.showBigZone} />
			</Dropzone>
		);
	}
}

const styles = theme => {
	const { button } = theme.typography;
	return {
		uploadDescription: {
			...button,
			display: "inline-block"
		},
		dndZoneWrapper: {
			position: "relative",
			border: 0,
			width: "auto",
			height: "auto"
		},
		buttonZone: {
			border: 0,
			width: "auto",
			height: "auto"
		}
	};
};

export default withStyles(styles)(OrderDocumentDndWrapper);

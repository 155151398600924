import React from "react";
import { withStyles } from "@material-ui/core";

const ShadowIcon = ({
	classes,
	children,
	inactive,
	containerComponent: Container = "div",
	rootClassName = inactive ? classes.inactive : classes.root
}) => (
	<Container className={rootClassName}>
		<div className={classes.shadow1}>
			<div className={classes.shadow2}>
				<div className={classes.shadow3}>{children}</div>
			</div>
		</div>
	</Container>
);

const styles = () => ({
	root: {
		display: "inline-block"
	},
	inactive: {
		composes: "$root",
		opacity: 0.38
	},
	shadow1: {
		filter: "drop-shadow(0 6px 7px rgba(0,0,0,0.2))"
	},
	shadow2: {
		filter: "drop-shadow(0 11px 15px rgba(0,0,0,0.14))"
	},
	shadow3: {
		filter: "drop-shadow(0 4px 20px rgba(0,0,0,0.12))"
	}
});

export default withStyles(styles)(ShadowIcon);

import React from "react";
import { Collapse } from "@material-ui/core";

const StateInfo = ({ explainers, state }) => {
	if (!state) return null;

	return (
		<Collapse in={!!state}>
			<div>
				<h2>{state}</h2>
				<div dangerouslySetInnerHTML={{ __html: explainers[state] }} />
			</div>
		</Collapse>
	);
};

const StateMassager = ({ jurisdictions, jurisdiction, ...props }) =>
	jurisdictions && jurisdiction ? (
		<StateInfo {...props} state={jurisdictions[jurisdiction].name} />
	) : null;

export default StateMassager;

import React from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography
} from "@material-ui/core";
import DocumentTableRow from "./table-row";

const DocumentReviewTable = ({ items }) =>
	items && items.length ? (
		<Table>
			<TableHead>
				<TableRow>
					<TableCell>Name</TableCell>
					<TableCell>Type</TableCell>
					<TableCell>Date Recorded</TableCell>
					<TableCell>Date Uploaded</TableCell>
				</TableRow>
			</TableHead>

			<TableBody>
				{items.map(d => (
					<DocumentTableRow key={d.id} {...d} />
				))}
			</TableBody>
		</Table>
	) : (
		<Typography component="p">No supporting documents uploaded.</Typography>
	);

export default DocumentReviewTable;

import React from "react";
import { Helmet } from "react-helmet";

import {
	Stepper,
	Card,
	CardContent,
	Collapse,
	Typography
} from "@material-ui/core";

import StepItem from "./step";
import StateChooser from "./state-chooser";
import PropertyDetails from "./property-details";
import Documents from "./documents";
import Review from "./review";

import connect from "./documents/connect";
import _DndWrapper from "./documents/dnd-wrapper";

const DndWrapper = connect(_DndWrapper);

const STATE_CHOOSER = "Choose State";
const PROPERTY_DETAILS = "Property Details";
const DOCUMENTS = "Supporting Documents";
const REVIEW = "Review";

const steps = [STATE_CHOOSER, PROPERTY_DETAILS, DOCUMENTS, REVIEW];

const NewOrderRoot = ({
	activeStep,
	classes,
	handlePrev,
	handleNext,
	handleSelect,
	content,
	...props
}) => {
	if (typeof activeStep !== "number") return null;

	const addressIsValid =
		props.accountNumberIsValid &&
		(props.hasNoAddress ||
			(props.propertyAddress1IsValid &&
				props.propertyCityIsValid &&
				props.propertyPostalCodeIsValid));

	const reviewStep = <Review key={3} {...props} content={content.review} />;

	const activeStepMap = [
		{
			contents: (
				<StateChooser
					key={0}
					{...props}
					explainers={content.explainers}
					content={content.chooseState}
				/>
			),
			validation: !!props.jurisdiction
		},
		{
			contents: (
				<PropertyDetails key={1} {...props} content={content.propertyDetails} />
			),
			validation: addressIsValid
		},
		{
			contents: (
				<Documents key={2} {...props} content={content.supportingDocuments} />
			),
			validation: true
		},
		{
			contents: reviewStep,
			validation: true
		}
	];

	const isDocumentsStep = activeStep === steps.indexOf(DOCUMENTS);

	// inline style on stepper is because it's ignoring
	// overrides in theme for whatever reason
	return (
		<div>
			<DndWrapper disabled={!isDocumentsStep} {...props}>
				<Card elevation={1} className={classes.root}>
					<CardContent>
						<Helmet>
							<title>{content.title}</title>
						</Helmet>

						<Typography variant="display1" gutterBottom>
							{content.title}
						</Typography>
						<Typography
							component="div"
							variant="body1"
							gutterBottom
							dangerouslySetInnerHTML={{ __html: content.intro }}
						/>
						<Collapse in={props.order && !props.order.isSubmitting}>
							<Stepper
								style={{ paddingRight: 0, paddingLeft: 0 }}
								activeStep={activeStep}
								orientation="vertical"
							>
								{Object.values(steps).map((step, stepIndex) => (
									<StepItem
										key={step}
										step={step}
										steps={steps}
										stepIndex={stepIndex}
										activeStep={activeStep}
										handlePrev={handlePrev}
										handleNext={handleNext}
										enableNext={activeStepMap[stepIndex].validation}
										handleSelect={handleSelect}
										order={props.order}
									>
										{props.order && props.order.isSubmitting
											? null
											: activeStepMap[stepIndex].contents}
									</StepItem>
								))}
							</Stepper>
						</Collapse>
						{props.order && props.order.isSubmitting ? (
							<StepItem
								containerComponent="div"
								step={REVIEW}
								steps={steps}
								stepIndex={steps.indexOf(REVIEW)}
								activeStep={activeStep}
								handlePrev={handlePrev}
								handleNext={handleNext}
								enableNext={activeStepMap[steps.indexOf(REVIEW)].validation}
								handleSelect={handleSelect}
								order={props.order}
							>
								{reviewStep}
							</StepItem>
						) : null}
					</CardContent>
				</Card>
			</DndWrapper>
		</div>
	);
};

export default NewOrderRoot;

import React, { Component } from "react";

import { CircularProgress, Typography } from "@material-ui/core";

import DocumentTable from "./table";
import Uploader from "./uploader";

import connect from "./connect";

class OrderDocuments extends Component {
	onUpload = files => {
		this.props.uploadOrderDocuments(this.props.jurisdiction, files);
	};

	render() {
		const { content, ...props } = this.props;

		if (props.isLoading) {
			return <CircularProgress />;
		}

		return (
			<div>
				<Typography
					component="div"
					variant="body1"
					gutterBottom
					dangerouslySetInnerHTML={{ __html: content }}
				/>
				<Uploader {...props} hasItems={props.items && props.items.length > 0}>
					<DocumentTable {...props} />
				</Uploader>
			</div>
		);
	}
}

export default connect(OrderDocuments);

import React, { Component } from "react";
import { Button, Paper, SvgIcon } from "@material-ui/core";
import Dropzone from "react-dropzone";
import { AttachFile } from "@material-ui/icons";
import ShadowIcon from "./shadow-icon";
import useSheet from "react-jss";

const FileUpload = props => (
	<SvgIcon {...props}>
		<path d="M9 16h6v-6h4l-7-7-7 7h4zm-4 2h14v2H5z" />
	</SvgIcon>
);

class OrderDocumentUploader extends Component {
	onUpload = files => {
		this.props.uploadOrderDocuments(this.props.jurisdiction, files);
	};

	render() {
		const { classes, children, hasItems } = this.props;
		return (
			<Paper>
				{children}
				<div
					className={
						hasItems
							? classes.instructionsContainer
							: classes.instructionsContainerEmpty
					}
				>
					{hasItems ? null : (
						<ShadowIcon inactive>
							<AttachFile className={classes.bigIcon} />
						</ShadowIcon>
					)}
					<div className={classes.flexBreak} />
					<span className={classes.uploadDescription}>Drag files here</span>
					<span className={classes.uploadDescriptionOr}>or</span>
					<Dropzone className={classes.buttonZone} onDrop={this.onUpload}>
						<Button variant="contained" color="secondary">
							<FileUpload className={classes.buttonIcon} />
							Choose Files for Upload
						</Button>
					</Dropzone>
				</div>
			</Paper>
		);
	}
}

const styles = theme => {
	const b = theme.typography.button;
	const u = theme.spacing.unit;
	const p = theme.palette;
	return {
		bigIcon: {
			display: "inline-block",
			color: "#fff",
			margin: [u * 5, 0],
			width: u * 16,
			height: u * 16
		},
		flexBreak: {
			width: "100%"
		},
		instructionsContainer: {
			margin: [u, "auto"],
			padding: [u * 2, 0],
			textAlign: "center",
			display: "flex",
			alignItems: "center",
			alignContent: "center",
			justifyContent: "center",
			flexDirection: "row",
			flexWrap: "wrap"
		},
		instructionsContainerEmpty: {
			composes: "$instructionsContainer",
			minHeight: u * 16,
			background: p.grey[50]
		},
		uploadDescription: {
			...b,
			display: "inline-block",
			verticalAlign: "middle",
			padding: [u, u * 2, u, 0]
		},
		uploadDescriptionOr: {
			composes: "$uploadDescription",
			color: p.text.secondary
		},
		buttonZone: {
			display: "inline-block",
			verticalAlign: "middle",
			border: 0,
			width: "auto",
			height: "auto"
		},
		buttonIcon: {
			margin: [-u, u, -u, u]
		}
	};
};

export default useSheet(styles)(OrderDocumentUploader);

import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

import { get } from "lodash";

import NewOrder from "../../orders/new";
import { Grid } from "react-bootstrap";

const NewOrderPage = ({
	data: { contentfulPurchaseTitleInsurance, allContentfulInsuranceExplainer },
	...props
}) => (
	<>
		<Helmet>
			<title>New Order</title>
		</Helmet>

		<Grid style={{ paddingTop: 16, paddingBottom: 16 }}>
			<NewOrder
				content={massageContent(
					contentfulPurchaseTitleInsurance,
					allContentfulInsuranceExplainer
				)}
				{...props}
			/>
		</Grid>
	</>
);

function massageContent(originalContent, explainerData) {
	if (!originalContent) return null;

	const {
		intro,
		chooseState,
		propertyDetails,
		supportingDocuments,
		review,
		...content
	} = originalContent;

	const explainers = {};
	explainerData.edges.forEach(({ node }) => {
		explainers[node.state] = get(node, "content.childMarkdownRemark.html");
	});

	return {
		...content,
		intro: extractHtml(intro),
		chooseState: extractHtml(chooseState),
		propertyDetails: extractHtml(propertyDetails),
		supportingDocuments: extractHtml(supportingDocuments),
		review: extractHtml(review),
		explainers
	};

	function extractHtml(node) {
		if (!node) return null;
		return get(node, "childMarkdownRemark.html");
	}
}

export default NewOrderPage;

export const pageQuery = graphql`
	query NewOrderPageQuery {
		contentfulPurchaseTitleInsurance(
			contentful_id: { eq: "6etkWDrweAYOWSeU6SGKQy" }
		) {
			title
			intro {
				childMarkdownRemark {
					html
				}
			}
			chooseState {
				childMarkdownRemark {
					html
				}
			}
			propertyDetails {
				childMarkdownRemark {
					html
				}
			}
			supportingDocuments {
				childMarkdownRemark {
					html
				}
			}
			review {
				childMarkdownRemark {
					html
				}
			}
		}
		allContentfulInsuranceExplainer {
			edges {
				node {
					state
					content {
						childMarkdownRemark {
							html
						}
					}
				}
			}
		}
	}
`;

import React, { Component } from "react";
import { TableCell, TableRow } from "@material-ui/core";
import moment from "moment";

import { DATE_DISPLAY_FORMAT } from "../table-row";

export default class DocumentReviewTableItem extends Component {
	handleTypeChange = ({ target: { value } }) => {
		this.props.updateOrderDocument(this.props.jurisdiction, this.props.id, {
			type: value
		});
	};

	handleDateChange = date => {
		this.props.updateOrderDocument(this.props.jurisdiction, this.props.id, {
			dateRecorded: moment(date)
		});
	};

	render() {
		const { name, createdOn, type, dateRecorded } = this.props;

		return (
			<TableRow>
				<TableCell>{name}</TableCell>
				<TableCell>{type}</TableCell>
				<TableCell>
					{dateRecorded
						? moment(dateRecorded).format(DATE_DISPLAY_FORMAT)
						: null}
				</TableCell>
				<TableCell>{moment(createdOn).fromNow()}</TableCell>
			</TableRow>
		);
	}
}

import { fetchOnUpdate } from "fetch-helpers";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
	fetchOrderDocuments,
	uploadOrderDocuments,
	updateOrderDocument
} from "../../actions";
import { getOrderDocuments } from "../../reducer";

export default WrappedComponent => {
	const fetcher = fetchOnUpdate(({ fetchOrderDocuments, jurisdiction }) => {
		if (jurisdiction) {
			fetchOrderDocuments(jurisdiction);
		}
	}, "jurisdiction")(WrappedComponent);

	return connect(
		(state, { jurisdiction }) => getOrderDocuments(state, jurisdiction),
		dispatch =>
			bindActionCreators(
				{ uploadOrderDocuments, fetchOrderDocuments, updateOrderDocument },
				dispatch
			)
	)(fetcher);
};

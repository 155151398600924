import React from "react";
import { MenuItem, Grid, Typography, withStyles } from "@material-ui/core";
import TextField from "./text-field";
import AddressDetails from "./address-details";
import { startCase } from "lodash";

const REFERENCE_NUMBER_NAMES = {
	ASSESSMENT: "Assessment",
	PARCEL: "Parcel",
	TAX_ACCOUNT: "TaxAccount"
};

const Col = props => <Grid item xs={12} sm={4} {...props} />;

const PropertyDetails = ({
	onChange,
	accountNumber,
	accountNumberIsDirty,
	accountNumberIsValid,
	referenceNumberName,
	content,
	classes,
	...props
}) => {
	const refNumberNamePicker = (
		<TextField
			className={classes.refNumPicker}
			select
			selectEmpty
			onChange={onChange}
			value={referenceNumberName}
			name="referenceNumberName"
		>
			{Object.values(REFERENCE_NUMBER_NAMES).map(n => (
				<MenuItem key={n} value={n}>
					{startCase(n)} Number
				</MenuItem>
			))}
		</TextField>
	);
	return (
		<div>
			<Typography
				component="div"
				variant="body1"
				gutterBottom
				dangerouslySetInnerHTML={{ __html: content }}
			/>

			<Grid container spacing={24}>
				<Col sm={12}>
					<div className={classes.refNumInputContainer}>
						{refNumberNamePicker}
						<TextField
							className={classes.refNumInput}
							error={accountNumberIsDirty && !accountNumberIsValid}
							required
							id="accountNumber"
							name="accountNumber"
							label={`${startCase(referenceNumberName)} Number`}
							placeholder="000012345678"
							value={accountNumber}
						/>
					</div>
				</Col>
			</Grid>
			<AddressDetails {...props} />
		</div>
	);
};

const styles = () => {
	return {
		refNumInputContainer: {
			display: "flex",
			flexDirection: "row"
		},
		refNumPicker: {
			flex: "0 1 auto"
		},
		refNumInput: {
			flex: "1 1 auto"
		}
	};
};

export default withStyles(styles)(PropertyDetails);

export const propertyDetailsFields = {
	accountNumber: { isRequired: true },
	referenceNumberName: { value: REFERENCE_NUMBER_NAMES.ASSESSMENT }
};

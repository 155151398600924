import React, { Component } from "react";
import {
	TableCell,
	TableRow,
	Select,
	MenuItem,
	LinearProgress,
	FormControl,
	Input,
	Dialog,
	IconButton,
	InputAdornment,
	Grow
} from "@material-ui/core";
import { Event } from "@material-ui/icons";
import DayPicker from "react-day-picker";
import moment from "moment";
import { Helmet } from "react-helmet";
import "react-day-picker/lib/style.css";

export const DATE_DISPLAY_FORMAT = "MMM Do, YYYY";

const DOCUMENT_TYPES = {
	CERTIFICATE: "Certificate",
	PICTURE_OF_CAT: "Picture of Cat",
	DEED: "Deed"
};

export default class DocumentTableItem extends Component {
	state = { isDayPickerOpen: false, dateFieldValue: undefined };
	handleOpenDayPicker = () => {
		this.setState({ isDayPickerOpen: true });
	};
	handleCloseDayPicker = () => {
		this.setState({ isDayPickerOpen: false });
	};
	handleTypeChange = ({ target: { value } }) => {
		this.props.updateOrderDocument(this.props.jurisdiction, this.props.id, {
			type: value
		});
	};

	handleDateChange = date => {
		const result = moment(date).format("YYYY-MM-DD");
		this.setState({
			isDayPickerOpen: false,
			dateFieldValue: moment(date).format("MMM Do, YYYY")
		});
		this.props.updateOrderDocument(this.props.jurisdiction, this.props.id, {
			dateRecorded: result
		});
	};

	handleDateTextChange = event => {
		if (event && event.target && event.target.value) {
			if (moment(event.target.value).isValid()) {
				this.handleDateChange(event.target.value);
			} else {
				this.setState({ dateFieldValue: event.target.value });
			}
		}
	};

	render() {
		const {
			name,
			createdOn,
			isUpdating,
			isUploading,
			type,
			dateRecorded
		} = this.props;

		if (isUploading) {
			return (
				<TableRow>
					<TableCell>{name}</TableCell>
					<TableCell colSpan={3}>
						<LinearProgress />
					</TableCell>
				</TableRow>
			);
		}

		return (
			<TableRow>
				<TableCell>{name}</TableCell>
				<TableCell>
					<Select
						aria-labelledby="documentTypeHeader"
						disabled={isUpdating}
						displayEmpty
						value={type || ""}
						onChange={this.handleTypeChange}
					>
						<MenuItem value="">Choose…</MenuItem>
						{Object.values(DOCUMENT_TYPES).map(t => (
							<MenuItem key={t} value={t}>
								{t}
							</MenuItem>
						))}
					</Select>
				</TableCell>
				<TableCell>
					<Helmet>
						<link
							href="https://fonts.googleapis.com/icon?family=Material+Icons"
							rel="stylesheet"
						/>
					</Helmet>
					<FormControl>
						<Input
							defaultValue={
								dateRecorded
									? moment(dateRecorded).format(DATE_DISPLAY_FORMAT)
									: null
							}
							value={this.state.dateFieldValue}
							disabled={isUpdating}
							onBlur={this.handleDateTextChange}
							endAdornment={
								<InputAdornment position="end">
									<IconButton onClick={this.handleOpenDayPicker}>
										<Event />
									</IconButton>
								</InputAdornment>
							}
						/>
					</FormControl>
					<Dialog
						open={this.state.isDayPickerOpen}
						onClose={this.handleCloseDayPicker}
						TransitionComponent={MyGrow}
					>
						<DayPicker
							month={dateRecorded ? new Date(dateRecorded) : new Date()}
							selectedDays={[
								dateRecorded ? new Date(dateRecorded) : new Date()
							]}
							aria-labelledby="documentRecordedDateHeader"
							onDayClick={this.handleDateChange}
						/>
					</Dialog>
				</TableCell>
				<TableCell>{moment(createdOn).fromNow()}</TableCell>
			</TableRow>
		);
	}
}

const MyGrow = props => <Grow mountOnEnter unmountOnExit {...props} />;

import React, { Component } from "react";
import {
	Step,
	StepButton,
	StepLabel,
	StepContent,
	Button,
	CircularProgress,
	withStyles
} from "@material-ui/core";

class StepItem extends Component {
	handleSelect = () => {
		this.props.handleSelect(this.props.stepIndex);
	};

	render() {
		const {
			step,
			stepIndex,
			activeStep,
			handlePrev,
			handleNext,
			steps,
			children,
			enableNext,
			order: { isSubmitting },
			classes,
			containerComponent: Wrapper = Step,
			...props
		} = this.props;

		delete props.handleSelect;

		const isLast = stepIndex === steps.length - 1;
		const isInStepper = Wrapper === Step;
		const Inner = isInStepper ? StepContent : "div";

		return (
			<Wrapper
				{...(isInStepper ? props : {})}
				orientation="vertical"
				completed={stepIndex < activeStep}
			>
				{isInStepper ? (
					<StepButton onClick={this.handleSelect}>
						<StepLabel icon={stepIndex}>{step}</StepLabel>
					</StepButton>
				) : null}
				<Inner>
					{children}
					<div>
						<br />
						<Button
							dense
							type={isLast ? "submit" : "button"}
							color="primary"
							variant="contained"
							disabled={!enableNext || isSubmitting}
							onClick={isLast ? undefined : handleNext}
						>
							{isSubmitting ? (
								<CircularProgress
									size={16}
									className={classes.submitIndicator}
								/>
							) : null}
							{isSubmitting ? "Submitting" : isLast ? "Check Out" : "Continue"}
						</Button>{" "}
						{stepIndex !== 0 && !isSubmitting ? (
							<Button dense onClick={handlePrev}>
								Back
							</Button>
						) : null}
					</div>
				</Inner>
			</Wrapper>
		);
	}
}

const styles = theme => ({
	submitIndicator: {
		marginRight: theme.spacing.unit
	}
});

export default withStyles(styles)(StepItem);

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { submitOrder } from "../actions";
import { getNewOrderStatus } from "../reducer";

export default WrappedComponent =>
	connect(
		state => ({
			order: getNewOrderStatus(state),

			// TODO: this needs to come from the server
			jurisdictions: {
				XLA: { name: "Louisiana", code: "LA" },
				XMO: { name: "Missouri", code: "MO" },
				XTN: { name: "Tennessee", code: "TN" }
			}
		}),
		dispatch => bindActionCreators({ submitOrder }, dispatch)
	)(WrappedComponent);

import React from "react";

import {
	FormGroup,
	FormControlLabel,
	MenuItem,
	Checkbox,
	Grid,
	Collapse
} from "@material-ui/core";
import TextField from "./text-field";

const BigCol = props => <Grid item xs={12} sm={6} {...props} />;
const Col = props => <Grid item xs={12} sm={6} md={4} {...props} />;

const AddressDetails = ({
	prefix = "property",
	onChange,
	hasNoAddress,
	onHasNoAddressChange,
	jurisdiction,
	jurisdictions,
	...props
}) => {
	const addressForm = (
		<Collapse in={!hasNoAddress}>
			<FormGroup row>
				<Grid container spacing={24}>
					<BigCol>
						<TextField
							fullWidth
							required
							value={props[`${prefix}Address1`]}
							id={`${prefix}Address1`}
							error={
								props[`${prefix}Address1IsDirty`] &&
								!props[`${prefix}Address1IsValid`]
							}
							name={`${prefix}Address1`}
							label="Street Address"
							placeholder="123 Main St"
						/>
					</BigCol>
					<BigCol>
						<TextField
							fullWidth
							value={props[`${prefix}Address2`]}
							name={`${prefix}Address2`}
							id={`${prefix}Address2`}
							label="Address #2"
							placeholder="Suite 101A"
						/>
					</BigCol>
					<Col>
						<TextField
							fullWidth
							required
							value={props[`${prefix}City`]}
							id={`${prefix}City`}
							error={
								props[`${prefix}CityIsDirty`] && !props[`${prefix}CityIsValid`]
							}
							name={`${prefix}City`}
							label="City"
							placeholder="Anytown"
						/>
					</Col>
					<Col>
						<TextField
							fullWidth
							onChange={onChange}
							error={
								props[`${prefix}StateIsDirty`] &&
								!props[`${prefix}StateIsValid`]
							}
							select
							disabled
							id={`${prefix}State`}
							name={`${prefix}State`}
							value={jurisdictions[jurisdiction].code}
							label="State"
						>
							<MenuItem value={jurisdictions[jurisdiction].code}>
								{jurisdictions[jurisdiction].name}
							</MenuItem>
						</TextField>
					</Col>
					<Col>
						<TextField
							fullWidth
							required
							error={
								props[`${prefix}PostalCodeIsDirty`] &&
								!props[`${prefix}PostalCodeIsValid`]
							}
							id={`${prefix}PostalCode`}
							name={`${prefix}PostalCode`}
							value={props[`${prefix}PostalCode`]}
							label="Postal Code"
							placeholder="123456"
						/>
					</Col>
				</Grid>
			</FormGroup>
		</Collapse>
	);

	return (
		<div>
			<FormControlLabel
				margin="normal"
				control={
					<Checkbox
						checked={hasNoAddress}
						onChange={onHasNoAddressChange}
						id="hasNoAddress"
						name="hasNoAddress"
					/>
				}
				label="This property does not have an address"
			/>
			{addressForm}
		</div>
	);
};

export default AddressDetails;

const notRequiredValidation = () => {
	return {
		isValid: true
	};
};

const postalCodeValidation = ({ value }) => {
	return {
		isValid: !!value.match(/(^\d{5}$)|(^\d{5}-\d{4}$)/)
	};
};

export const addressDetailsFields = ({
	isRequired = true,
	prefix = "property"
}) => ({
	[`${prefix}Address1`]: { isRequired },
	[`${prefix}Address2`]: { isRequired: false },
	[`${prefix}City`]: { isRequired },
	[`${prefix}PostalCode`]: {
		validation: isRequired ? postalCodeValidation : notRequiredValidation
	}
});
